import mux from 'mux-embed';
import { assign } from 'utilities/obj.js';
import { elemBind } from 'utilities/elem.js';
import * as Judy from 'utilities/judy.js';
import { PLAYER_VERSION } from '../utilities/player-version.js';

const secondsToMs = mux.utils.secondsToMs;

const ERROR_MESSAGES = {
  1: 'MEDIA_ERR_ABORTED',
  2: 'MEDIA_ERR_NETWORK',
  3: 'MEDIA_ERR_DECODE',
  4: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
};

export const init = (video, options = {}) => {
  let videoEl;

  const killBindings = () => {
    if (video.mux.unbinds) {
      video.mux.unbinds.map((u) => u());
      video.mux.unbinds = [];
    }
  };

  // Retrieve the ID and the player element
  const playerID = video.uuid;

  // Enable customers to emit events through the player instance
  video.mux = { unbinds: [] };
  video.mux.emit = function (eventType, data) {
    if (!video.mux._didInit) {
      return;
    }

    mux.emit(playerID, eventType, data);
  };

  video.mux.destroy = () => {
    video.mux._destroyed = true;
    killBindings();
    video.mux.emit('destroy');
  };

  let playerType = video.playerType;

  if (video.popover) {
    playerType = `popover_${playerType}`;
  }

  if (/vulcan/.test(video.playerType)) {
    const bestEngine = Judy.bestUsableEngine(video.judyCtx(), video._mediaData, video._opts);

    if (bestEngine === 'engines/hls_video.js') {
      playerType = `${playerType}_hlsjs`;
    }

    if (bestEngine === 'engines/native_hls_video.js') {
      playerType = `${playerType}_nativehls`;
    }

    if (bestEngine === 'engines/simple_audio.js') {
      playerType = `${playerType}_simple_audio`;
    }
  }

  if (
    video.playerType === 'html5' &&
    video._impl._currentAsset &&
    video._impl._currentAsset.ext == 'm3u8'
  ) {
    playerType = `${playerType}_nativehls`;
  }

  options.minimumRebufferDuration = 250;

  options.disableCookies = true;

  // Use the tagged version if it's available, otherwise use the fallback player version
  const playerVersion =
    __TAGGED_VERSION__ !== '' && __TAGGED_VERSION__.length > 0
      ? __TAGGED_VERSION__
      : PLAYER_VERSION;

  options.data = assign(
    {
      player_software: video.playerType,
      player_software_version: playerVersion,
      player_mux_plugin_name: 'wistia-mux',
      player_mux_plugin_version: '4.6.0',
      env_key: __MUX_PROPERTY_KEY__,
      page_type: video._opts._inIframe ? 'iframe' : '',
      viewer_user_id: video.visitorKey(),
      player_name: `${playerType}-${playerVersion}`,
      player_version: playerVersion,
      player_init_time: video._initTime,
      video_stream_type: video._mediaData.type === 'LiveStream' ? 'live' : 'vod',
      video_id: video.hashedId(),
      video_title: `${video.hashedId()} - ${video.name()}`,
      video_producer: '',
      video_language_code: '',
      video_variant_name: '',
      video_variant_id: '',
      video_duration: secondsToMs(video.duration()),
      video_encoding_variant: '',
      video_series: video._opts.channel,
      sub_property_id: video._mediaData.accountKey.match(/\d+/)[0],
      // Configured custom data
      // https://docs.mux.com/guides/extend-data-with-custom-metadata#4-submitting-custom-metadata-from-mux-data-sdks
      // Embed type for the rollout of the web component. Values can be 'legacy', 'web-component', or 'translated-web-component'.
      custom_1: options.embedType ?? '',
      // General location of the embed.
      custom_2:
        window.location?.protocol?.length && window.location?.hostname?.length
          ? `${window.location.protocol}//${window.location.hostname}`
          : '',
    },
    options.data,
  );

  options.getPlayheadTime = () => {
    return secondsToMs(video.time());
  };

  options.getStateData = () => {
    return {
      // Required properties - these must be provided every time this is called
      // You _should_ only provide these values if they are defined (i.e. not 'undefined')
      player_is_paused: !videoEl || videoEl.paused,
      player_width: video.videoWidth(),
      player_height: video.videoHeight(),
      video_source_height: videoEl ? videoEl.videoHeight : video.videoHeight(),
      video_source_width: videoEl ? videoEl.videoWidth : video.videoWidth(),

      // Preferred properties - these should be provided in this callback if possible
      // If any are missing, that is okay, but this will be a lack of data for the customer at a later time
      player_is_fullscreen: video.inFullscreen(),
      player_autoplay_on: !!video._attrs.autoplay,
      player_preload_on: videoEl ? videoEl.getAttribute('preload') !== 'none' : undefined,
      video_source_url: videoEl && videoEl.src,
      video_source_mime_type: videoEl && videoEl.getAttribute('type'),
      video_source_duration: secondsToMs(video.duration()),

      // Optional properties - if you have them, send them, but if not, no big deal
      video_poster_url: video.stillUrl(),
      player_language_code: video.playerLanguage(),
    };
  };

  const handleEvent = (eventType, ...args) => {
    const data = args[0];

    switch (eventType) {
      case 'pause':
        video.mux.emit('pause');
        break;
      case 'playcalled':
        video.mux.emit('play');
        break;
      case 'playrejected':
        video.mux.emit('pause');
        break;
      case 'play':
        video.mux.emit('playing');
        break;
      case 'el-seeking':
        video.mux.emit('seeking');
        break;
      case 'el-seeked':
        video.mux.emit('seeked');
        break;
      case 'el-timeupdate':
        video.mux.emit('timeupdate');
        break;
      case 'el-error':
        emitErrorFromEvent(args[0]);
        break;
      case 'end':
        video.mux.emit('ended');
        break;
      case 'hlslevelswitched':
        video.mux.emit('renditionchange', {
          video_source_bitrate: data.asset?.metadata?.average_bitrate,
          video_source_height: videoEl ? videoEl.videoHeight : video.videoHeight(),
          video_source_width: videoEl ? videoEl.videoWidth : video.videoWidth(),
        });
        break;
      case 'beforeremove':
        video.mux.emit('destroy');
        killBindings();
        break;
      case 'beforereplace':
        video.mux.emit('destroy');
        killBindings();
        break;
      default:
    }
  };

  const emitErrorFromEvent = (e) => {
    let error = e;

    // Chrome v60
    if (e.path && e.path[0]) {
      error = e.path[0].error;
    }

    // Firefox v55
    if (e.originalTarget) {
      error = error.originalTarget.error;
    }

    video.mux.emit('error', {
      player_error_code: error && error.code,
      player_error_message: error && ERROR_MESSAGES[error.code],
    });
  };

  video.whenVideoElementInDom().then((el) => {
    videoEl = el;

    mux.init(playerID, options);
    video.mux._didInit = true;

    // Kill existing bindings, just in case
    killBindings();

    video.mux.unbinds.push(video.on('all', handleEvent));

    video.mux.unbinds = video.mux.unbinds.concat(
      ['timeupdate', 'seeking', 'seeked', 'error'].map((eventType) => {
        return elemBind(videoEl, eventType, (event) => {
          handleEvent(`el-${eventType}`, event);
        });
      }),
    );

    video.embedded(() => {
      setTimeout(() => {
        if (!video.mux._destroyed) {
          video.mux.emit('playerready', {
            viewer_time: video._embeddedTime,
          });

          if (video.ready() && videoEl && !videoEl.paused) {
            const lastPlayInfo = video.lastPlayInfo() || {};
            // if wistia-mux has loaded after the player started playing, we
            // can usually assume that it was from autoplay--if we don't have
            // lastPlayInfo(), let's say the play was issued on ready().
            video.mux.emit('play', {
              viewer_time: lastPlayInfo.issuedAt || video._readyTime,
            });
            if (lastPlayInfo.resolvedAt) {
              video.mux.emit('playing', {
                viewer_time: lastPlayInfo.resolvedAt,
              });
            }
          }
        }
      }, 1);
    });
  });
};

export default init;
